@tailwind base;
@tailwind components;
@tailwind utilities;

.markdown > * {
  all: revert;
}

.markdown img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.markdown h6{
  text-align: center;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
  /* Replace #ff0000 and #0000ff with your desired start and end colors */

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffffff7a;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
